import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/tutorials.tsx";
export const pageTitle = "Implementing CREATE operation";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TutorialSteps = makeShortcode("TutorialSteps");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "implementing-create-operation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#implementing-create-operation",
        "aria-label": "implementing create operation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Implementing CREATE operation`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#what-you-need"
        }}>{`What you need`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#1-implement-server-side"
        }}>{`1. Implement server-side`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#2-register-a-serverextension"
        }}>{`2. Register a ServerExtension`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#3-set-up-a-client-for-testing"
        }}>{`3. Set up a client for testing`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#4-test-creating-a-blog-post"
        }}>{`4. Test creating a blog post`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#whats-next"
        }}>{`What's next`}</a></li>
    </ul>
    <p>{`In the previous step, we defined service methods.
In this step, we'll implement one of the service methods to create a blog post and again, try making a call to a service method.
Also, we'll use Armeria's `}<a parentName="p" {...{
        "href": "type://ServerExtension:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/testing/junit5/server/ServerExtension.html"
      }}>{`type://ServerExtension`}</a>{` for testing.`}</p>
    <TutorialSteps current={3} mdxType="TutorialSteps" />
    <h2 {...{
      "id": "what-you-need",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-you-need",
        "aria-label": "what you need permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What you need`}</h2>
    <p>{`You need to have the following files obtained from previous steps.
You can always `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/tree/main/tutorials/grpc"
      }}>{`download`}</a>{` the full version, instead of creating one yourself.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tutorials/grpc/blog/define-service#6-compile-the-proto-file"
        }}>{`Generated Java code`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogService.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Main.java`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`BlogServiceTest.java`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "1-implement-server-side",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1-implement-server-side",
        "aria-label": "1 implement server side permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Implement server-side`}</h2>
    <p>{`Let's implement a service method `}<inlineCode parentName="p">{`createBlogPost()`}</inlineCode>{` in the `}<inlineCode parentName="p">{`BlogService`}</inlineCode>{` class.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create an ID generator to issue temporary blog post IDs and a map to contain blogs posts.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogService.java",
            "filename": "BlogService.java"
          }}>{`import java.util.Map;
import java.util.concurrent.ConcurrentHashMap;
import java.util.concurrent.atomic.AtomicInteger;

public final class BlogService extends BlogServiceGrpc.BlogServiceImplBase {
  private final AtomicInteger idGenerator = new AtomicInteger();
  private final Map<Integer, BlogPost> blogPosts = new ConcurrentHashMap<>();
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add a service method, `}<inlineCode parentName="p">{`createBlogPost()`}</inlineCode>{`:`}</p>
        <ol parentName="li">
          <li parentName="ol">{`Create a `}<inlineCode parentName="li">{`BlogPost`}</inlineCode>{` object with request parameters.`}</li>
          <li parentName="ol">{`Save the post information in the map.`}</li>
          <li parentName="ol">{`Return the information of the blog post created.`}</li>
        </ol>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogService.java",
            "filename": "BlogService.java"
          }}>{`import java.time.Instant;
import io.grpc.stub.StreamObserver;

final class BlogService extends BlogServiceGrpc.BlogServiceImplBase {
  @Override
  public void createBlogPost(CreateBlogPostRequest request, StreamObserver<BlogPost> responseObserver) {
    final int id = idGenerator.getAndIncrement(); // Generate post ID
    final Instant now = Instant.now();            // Get current time
    final BlogPost updated = BlogPost.newBuilder()
                     .setId(id)
                     .setTitle(request.getTitle())
                     .setContent(request.getContent())
                     .setModifiedAt(now.toEpochMilli())
                     .setCreatedAt(now.toEpochMilli())
                     .build();
    blogPosts.put(id, updated);
    responseObserver.onNext(updated);
    responseObserver.onCompleted();
  }
}
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "2-register-a-serverextension",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#2-register-a-serverextension",
        "aria-label": "2 register a serverextension permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Register a ServerExtension`}</h2>
    <p>{`In the previous test code, we've connected directly to the server.
This time, let's use Armeria's `}<a parentName="p" {...{
        "href": "type://ServerExtension:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/testing/junit5/server/ServerExtension.html"
      }}>{`type://ServerExtension`}</a>{` instead.
This approach automatically handles set-up and tear-down of a server for testing.
Now we don't have to invoke the main method to set up a server before running our tests.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`BlogServiceTest`}</inlineCode>{` class, remove the `}<inlineCode parentName="p">{`connect()`}</inlineCode>{` method to eliminate dependency on the main method.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceTest.java",
            "filename": "BlogServiceTest.java"
          }}>{`public class BlogServiceTest {
  ...
  @Test
  void connect() {...} // Remove this method
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Register a `}<a parentName="p" {...{
            "href": "type://ServerExtension:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/testing/junit5/server/ServerExtension.html"
          }}>{`type://ServerExtension`}</a>{` as follows.
Note that the service instance is added to the configuration.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceTest.java",
            "filename": "BlogServiceTest.java"
          }}>{`import org.junit.jupiter.api.extension.RegisterExtension;
import com.linecorp.armeria.server.ServerBuilder;
import com.linecorp.armeria.server.grpc.GrpcService;
import com.linecorp.armeria.testing.junit5.server.ServerExtension;

public class BlogServiceTest {
  ...
  @RegisterExtension
  static final ServerExtension server = new ServerExtension() {
    @Override
    protected void configure(ServerBuilder sb) throws Exception {
      sb.service(GrpcService.builder()
                    // Add the service to the configuration
                    .addService(new BlogService())
                    .build());
    }
  };
}
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "3-set-up-a-client-for-testing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#3-set-up-a-client-for-testing",
        "aria-label": "3 set up a client for testing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Set up a client for testing`}</h2>
    <p>{`In the `}<inlineCode parentName="p">{`BlogServiceTest`}</inlineCode>{` class, add a method as follows.
This will set up a client before executing each test cases.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogServiceTest.java",
        "filename": "BlogServiceTest.java"
      }}>{`import org.junit.jupiter.api.BeforeAll;

@BeforeAll
static void beforeAll() {
  client = GrpcClients.newClient(server.httpUri(),
                 BlogServiceBlockingStub.class);
}
`}</code></pre>
    <h2 {...{
      "id": "4-test-creating-a-blog-post",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#4-test-creating-a-blog-post",
        "aria-label": "4 test creating a blog post permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Test creating a blog post`}</h2>
    <p>{`Let's test if we can create a blog post.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`In the `}<inlineCode parentName="p">{`BlogServiceTest`}</inlineCode>{` class, add a test method as follows.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=BlogServiceTest.java",
            "filename": "BlogServiceTest.java"
          }}>{`import static org.assertj.core.api.Assertions.assertThat;
import com.fasterxml.jackson.core.JsonProcessingException;

@Test
void createBlogPost() throws JsonProcessingException {
  final CreateBlogPostRequest request = CreateBlogPostRequest.newBuilder()
                   .setTitle("My first blog")
                   .setContent("Hello Armeria!")
                   .build();
  final BlogPost response = client.createBlogPost(request);
  assertThat(response.getTitle()).isEqualTo(request.getTitle());
  assertThat(response.getContent()).isEqualTo(request.getContent());
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Run the test case on your IDE or using Gradle.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`./gradlew test
`}</code></pre>
      </li>
    </ol>
    <p>{`The service worked as expected if you see the test case passed.`}</p>
    <h2 {...{
      "id": "whats-next",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#whats-next",
        "aria-label": "whats next permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What's next`}</h2>
    <p>{`In this step, we've implemented a method for creating a blog post.
We've also registered `}<a parentName="p" {...{
        "href": "type://ServerExtension:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/testing/junit5/server/ServerExtension.html"
      }}>{`type://ServerExtension`}</a>{` to our test.`}</p>
    <p>{`Next, at `}<a parentName="p" {...{
        "href": "/tutorials/grpc/blog/implement-read"
      }}>{`Step 4. Implement READ`}</a>{`, we'll implement a READ operation to read a single post and also multiple posts.`}</p>
    <TutorialSteps current={3} mdxType="TutorialSteps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      